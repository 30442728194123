<template>
  <div>
    <div class="list-items">
      <div
        class="list-items-actions"
        v-if="!userHasRole('vendors-viewer')"
      >
        <Button
          :to="{path: '/admin/users/add'}"
          type="primary"
          class="btn btn--elevated"
          icon="md-add"
        >
          {{ $t('global.user.new') }}
        </Button>
      </div>
    </div>
    <div>
      <Row>
        <Col span="24">
          <FilterTable
            name="users"
            :trigger="trigger"
            :container="true"
            :fetch-items="this.userGetUsers"
            :allow-download="false"
            :default-paging="{direction: 'desc', sort: 'Users.modified'}"
            namespace="user"
            :columns="columns"
            id-column="Name"
            :limit="20"
            :search-filters="['search']"
          />
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import FilterTable from '@/components/ui/FilterTable'
import pageConfig from '@/mixins/pageConfig'
import tableColumn from '@/mixins/tableColumn'

export default {
  name: 'AdminUserIndex',
  components: {
    FilterTable
  },
  mixins: [
    pageConfig,
    tableColumn
  ],
  data () {
    return {
      trigger: false,
      loading: true,
      /**
       * @note createColumn -> @/mixins/tableColumn.js
       */
      columns: [
        this.createColumn(this.$t('global.table.name'), 'name', 'Users.name', row => ({
          tag: 'strong',
          content: row.name
        })),
        {
          title: this.$t('global.table.roles'),
          render: (h, params) => params.row.roles.map(role => h('span', {class: 'chips chips--light'}, role.name)),
          width: 250
        },
        this.createColumn(this.$t('global.table.status'), 'status', null, 'enable'),
        this.createColumn(this.$t('global.table.last_login'), null, 'lastLogin', row => ({
          tag: 'span',
          content: row.lastLogin ? moment(row.lastLogin).fromNow() : 'None'
        })),
        {
          title: this.$t('global.table.modified'),
          key: 'modified',
          sortKey: 'Users.modified',
          sortable: 'custom',
          render: (h, params) => h('span', {}, moment(params.row.modified).format('LLL')),
          width: 250
        },
        this.createColumn(this.$t('global.table.manage'), null, null, row => ({
          tag: 'Button',
          classes: 'btn btn--elevated',
          props: {type: 'primary', to: `users/view/${row.id}`},
          content: this.$t('global.table.manage')
        }))
      ]
    }
  },
  methods: {
    ...mapActions({
      userGetUsers: 'user/getUsersOld'
    })
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole'
    })
  }
}
</script>
