import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      pageBaseConf: {},
      filtersBaseConf: []
    }
  },
  computed: {
    ...mapGetters({
      redirectConfigList: 'notifications/redirects'
    })
  },
  methods: {
    applyConfig (configuration) {
      const {pageBaseConf} = this
      pageBaseConf.tab = configuration.tabs || false
      pageBaseConf.sort = configuration.sort || false
      this.filtersBaseConf = configuration.filters.length > 0 ? configuration.filters : false
      return true
    }
  }
}
